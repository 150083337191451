.completed{
    background-color: #BC9BFA !important;   
    color:#fff !important;
    margin-right: 1px !important;
    &:first-of-type{
        border-radius:30px 0 0 30px !important;
    }
    &:nth-of-type(3){
        border-radius:0 30px 30px 0 !important;
        margin: 0 !important;
    }
    span{
        display: block;
        width: 23px;
        height: 23px;
        background-color: transparent !important;
        border:1px solid #fff !important;
        color:#fff !important;
    }
}   

.label{
    span:nth-of-type(2) {
        color: #9f9f9f;
        font-size: 14px;
        span {
            font-weight: 700;
        }
    }
}
