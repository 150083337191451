.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 16px;
  background-color: #fff;
  padding: 32px 16px;
}

@media (min-width: 640px) {
  .container {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 64rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.containerTwo {
  display: flex;
  flex-direction: column;
}

.containerTwo>*:nth-child(2n) {
  border-right: none;
}

@media (min-width: 768px) {
  .containerThree {
    border-radius: 16px;
    background-color: #fff;
    padding: 32px 16px;
  }
}

.month {
  flex: 1 1 auto;
  font-size: 16px;
  font-weight: 600;
  color: #666;
  text-align: center;
  text-transform: capitalize;
}

.prev-month-btn {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0.4rem;
  color: #666;
  font-weight: 900;
  background-color: #d9d9d966;
  border-radius: 50%;
}

.prev-month-btn:hover {
  background-color: rgb(229, 231, 235);
  border-radius: 50%;
  cursor: pointer;
}

.next-month-btn {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
  margin-right: -0.375rem;
  margin-left: 0.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0.4rem;
  color: #666;
  font-weight: 900;
  background-color: #d9d9d966;
  border-radius: 50%;
}

.next-month-btn:hover {
  background-color: rgb(229, 231, 235);
  border-radius: 50%;
  cursor: pointer;
}

.weekDaysContainer {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  margin-top: 24px;
  font-size: 0.75rem;
  line-height: 1.5;
  text-align: center;
  color: #666;
}

.weekDaysContainer>div {
  color: #666;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

@media (max-width: 1100px) {
  .weekDaysContainer > div {
    font-size: 12px;
  }
}

.monthDaysContainer {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  margin-top: 0.5rem;
  font-size: 0.875rem;
}

.menu-items {
  position: absolute;
  right: 0;
  z-index: 10;
  margin-top: 0.5rem;
  transform-origin: top right;
  background-color: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  width: 9rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  outline: none;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.font-semibold {
  font-weight: 600;
  background-color: #d9d9d966;
}

.styles {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto;
  border-radius: 50%;
}

.text-white {
  color: #666;
  background-color: rgb(156, 163, 175);
}

.text-gray900 {
  color: rgb(17, 24, 39);
}

.text-gray400 {
  color: rgb(156, 163, 175);
}

.isToday-selected {
  background: rgb(229, 231, 235);
  border: 1px solid #bc9bfa;
}

.isToday {
  background: #bc9bfa;
  border: 1px solid #bc9bfa;
  .dayDate {
    color: #fff;
  }
}

.day-selected {
  background: rgb(229, 231, 235);
}

.bg-isToday {
  border: 1px solid #bc9bfa;
}

.bg-hover:hover {
  background-color: rgb(229, 231, 235);
}

button {
  border: none;
  background-color: transparent;
}

.daysBtn {
  flex-direction: column;
  transition: background 0.3s ease-in-out;
}

.daysBtn:hover {
  cursor: pointer;
  background: rgb(229, 231, 235);
}

.daysEvent {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.daysEvent > h2 {
  align-items: center;
  color: #666;
  display: flex;
  font-weight: 600;
  gap: 8px;
  margin-top: 16px;
  font-size: 18px;
  margin-bottom: 0;
}

@media (max-width: 1400px) and (min-width: 900px) {
  .daysEvent > h2 {
    font-size: 18px;
  }
}
@media (max-width: 1100px) {
  .daysEvent > h2 {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .daysEvent {
    margin-top: 0;
  }
}

.dayDate {
  color: #9f9f9f;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

@media (max-width: 1100px) {
  .dayDate {
    font-size: 12px;
  }
}

.activityList {
  color: #6b7280;
  display: flex;
  font-size: 0.875rem;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0;
  width: 100%;
  max-height: 184px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ccc #f9f9f9;
  padding-right: 8px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #f9f9f9;
  }

  &::-webkit-scrollbar-thumb {
    background: #bc9bfa;
    border-radius: 20px;
  }
}

.noActivity {
  margin: 0;
  margin-top: 16px;
  text-align: center;

}

.statusContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 1400px) {
  .statusContainer {
    flex-direction: column;
    gap: 12px;
  }
}

.navigate {
  font-size: 14px;
  font-weight: 500;
}

.tableRow > td:first-of-type {
  padding-top: 8px;
}

