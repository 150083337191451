.login {
  height: 100vh;
  background-color: #d4d4d4 !important;

  &__section {
    display: grid;
    gap: 14px;
    height: 100vh;
    width: 333px;
    margin: auto;
    grid-template-rows: 60px 220px 150px 100px;
    grid-template-areas:
      '.'
      'students'
      'profLogo'
      'loginBtn'
    ;
  }

  &__students {
    grid-area: students;
    background-color:#06A9C3;
    border-radius:29px 29px 0 0;
    height: 177px;
    position: absolute;
    top:118px;

    img {
      height: 221px;
      position: relative;
      top:-43px;
      width: 333px;
      z-index: 1;
    }
  }

  &__logo {
    grid-area: profLogo;
    img {
      width: 333px;
    }
  }

  &__button {
    grid-area: loginBtn;
    a {
      border-radius: 8px;
      color: #E8ECEF;
      font-size: 24px;
      height: 50px;
      position: absolute;
      padding: 5px;
      margin: 3rem 36.5px 0 36.5px;
      text-align: center;
      width: 260px;
    }
  }
}
