.completed {
  background-color: #BC9BFA !important;
  color: #fff !important;
  margin-right: 1px !important;

  &:first-of-type {
    border-radius: 30px 0 0 30px !important;
  }

  &:last-of-type {
    border-radius: 0 30px 30px 0 !important;
    margin: 0 !important;
  }

  span {
    display: block;
    width: 23px;
    height: 23px;
    background-color: transparent !important;
    border: 1px solid #fff !important;
    color: #fff !important;
  }
}

.format_title {
  text-align: center;
  margin-top: 0;
}

.format_bt {
  font-family: Montserrat, sans-serif;
  color: #9F9F9F;
  display: flex;
  width: 143px;
  border-radius: 8px;
  height: 96px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  border: 1px dashed #9F9F9F;

  &.selected {
    background-color: #BC9BFA;
    border: 1px solid #BC9BFA;
    color: #fff;

    img, svg {
      filter: brightness(0) invert(1);
    }
  }

  svg {
    fill: #b5b3b3 !important;
    font-size: 39px !important;
    margin-bottom: 4px
  }

  img {
    width: 39px;
    margin: 0 0 4px;
  }

  &:hover {
    background-color: #BC9BFA;
    border: 1px solid #BC9BFA;
    color: #fff;

    img, svg {
      filter: brightness(0) invert(1);
    }
  }


}

.inputFile {
  display: none;
}
