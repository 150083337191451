.datepickerContainer {
  & .react-datepicker__header {
    background: none;
  }
}

.file_name{
  margin: 0;
  font-size: 12px;
  border: 1px dashed #666;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  position: relative;

  a{
      position: absolute;
      display: inline-block;
      right: 8px;
      cursor: pointer;
      height: 18px;
  }
}

.file_icon{
  background-color: #F0F3F4;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img{
      margin: 0;
  }
}

.file_paragraph{
  margin: 0;
  font-size: 12px;
}

.btFile{
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  padding: 30px;
  text-transform: none;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: none;
  display: flex;
  color: #9F9F9F;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: .4s ease;

  div{
      margin-bottom: 16px;
  }

  p{
      font-family: Montserrat,sans-serif;
      font-size: 12px;
      line-height: 1.6em;
  }

  &:hover{
      box-shadow: 1px 1px 5px 0px #525252;
  }
}

