.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #412A88;
  box-shadow: 32px 0 #412A88, -32px 0 #412A88;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #422a8922;
    box-shadow: 32px 0 #422a8922, -32px 0 rgb(66, 42, 137);
  }
  50% {
    background-color: #412A88;
    box-shadow: 32px 0 #422a8922, -32px 0 #422a8922;
  }
  100% {
    background-color: #422a8922;
    box-shadow: 32px 0 #412A88, -32px 0 #422a8922;
  }
}
