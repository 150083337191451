.titleImg {
  svg {
    margin-right: 16px;
    height: 20px;
    width: 15px;
    vertical-align: text-top;
  }
  // & path {
  //   fill: #9F9F9F;
  // }
}