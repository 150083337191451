.taskInfoBox {
  display: flex;
  flex-flow: column;
  padding-top: 8px;
}

.data,
.application,
.user,
.type {
  font-size: 14px;
  margin: 0 16px;

  & div:first-of-type {
    color: #666666;
    margin-bottom: 8px;
  }

  & div:last-of-type {
    color: #9F9F9F;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #D9D9D94D;
  }
}

.type {
  & div:last-of-type {
    border-bottom: none;
  }
}

.dialogInfo {
  color: #9F9F9F;
  font-weight: 600;
  margin: 6px 16px;
}

.menuDownloadIcon {
  display: flex;
  align-items: center;
  gap: 6px;
  svg {
    width: 16px;
    path {
      stroke: #9F9F9F;
    }
  }
}
