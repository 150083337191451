.table {
  scrollbar-width: thin;
  scrollbar-color: #ccc #f9f9f9;
}

.table::-webkit-scrollbar {
  width: 5px;
}

.table::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.table::-webkit-scrollbar-thumb {
  background-color: #f0f3f4;
  border-radius: 20px;
}
