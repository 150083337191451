.dashboard {
  display: grid;
  background-color: #F0F3F4;
  grid-template-rows: 56px 100%;
  grid-template-areas:
    "sidebar navbar"
    "sidebar content"
  ;
  transition: grid-template-columns 0.3s ease-in-out;
}

.dashboardSidebar {
  grid-area: sidebar;
}

.dashboardNavbar {
  grid-area: navbar;
}

.dashboardContent {
  grid-area: content;
  min-height: calc(100vh - 56px);
  padding: 24px;

  @media (max-width: 1241px) {
    padding: 24px 16px;
  }
}
